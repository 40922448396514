<!--Pagina de mi perfil-->
<template>
    <v-container fill-height class="align-start black" fluid>
    <v-row class="" justify="center" no-gutters>
        <v-col cols="12" md="11"  class="mb-2 fondo3">
        <menus/>
            <v-row class="text-center"  justify="center" no-gutters>
                <v-col cols="0" md="3" class="fondo1 d-none d-md-inline">&nbsp;</v-col>
                <v-col cols="12" md="6">
                <v-responsive  align="center" class="white--text" >
                    <div class="font-weight-black text-center my-10">
                        <h1>MI PERFIL</h1>
                    </div>
                    <v-row class="justify-center" no-gutters>
                        <v-col cols="6" class="justify-center d-flex">
                            <!-- <v-btn color="becks" block  elevation="2" class="mx-auto" style="z-index: 2" rounded>
                                <span class="textoR">MIS PARTICIPACIONES</span>
                            </v-btn> -->
                            <h3 class="white--text">MIS PARTICIPACIONES</h3>
                        </v-col>
                    </v-row>
                    <v-row justify="start" >
                        <!--Columna de participaciones, una por cada participacion-->
                        <v-col cols="6" md="4" 
                            v-for="(item, i) in fotos"
                            :key="i" 
                            @click="modal = !modal; cambiarImagen(i); reviewUrl(item.url_image) "
                            style="cursor: pointer;"
                        >
                            <participantes 
                                tipo="1"
                                :urlimage="item.url_image"
                                :fecha="item.fecha"/>
                        </v-col>
                        
                    </v-row>
                    <!--
                    <h1 class="mt-10">
                        TUS ENTRADAS
                    </h1>
                    <v-card v-if="show_events != 'apagar'" dark rounded="xl" min-height="100" class="d-flex align-center px-2 my-10">
                        <fiesta  
                            v-for="(item, i) in party"
                            :key="i"
                            :dia="item.event_day"
                            :mes="item.event_month"
                            :lugar="item.event_hour + ' ' + item.event_place"
                            :artista="item.event_name"
                            :id="item.event_id"
                            :provider_id="provider_id"
                        />
                    
                    </v-card>
                    -->
                    <v-card color="transparent" class="py-15" rounded="xl">
                            <h3 class="my-3 d-none">ESCUCHA NUESTRAS PLAYLIST</h3>
                            <v-btn
                                class="mx-auto black becks--text d-none"
                                rounded
                                @click="cambiarP('Encuesta')"
                                min-width="350px"
                            >
                                ESCUCHAR
                            </v-btn>
                        </v-card>
                </v-responsive>
                </v-col>
                <v-col cols="0" md="3" class="fondo2 d-none d-md-inline">&nbsp;</v-col>
            </v-row>
        </v-col>
        <v-dialog
            max-width="400px"
            v-model="modal"
            class="black"
        >   <v-card
                color="rgba(0,0,0,0)"
                class="text-center"
                max-width="400px"
                elevation="0"
            >
                <v-card rounded="xl" width="85%" dark outlined style="border: white solid 1px" color="#373737" class="mb-2 mx-auto mt-5" elevation="0">
                    <v-responsive  align="center" width="100%" :aspect-ratio="1" class="justify-center align-center text-center ">
                        <v-img
                            contain
                            :aspect-ratio="1"
                            min-width="200"
                            class=""
                            :src="imagenGrande"
                        >
                        </v-img>
                    </v-responsive>
                </v-card>
                <v-btn
                    class="mt-5 d-none d-md-flex mx-auto"
                    elevation="2"
                    color="becks"
                    rounded
                    @click="dialog= !dialog; control = 1"
                >
                    <v-icon>mdi-share-variant</v-icon>&nbsp;Comparte tu foto
                </v-btn>
                <v-btn
                    class="mt-5 d-md-none mx-auto"
                    elevation="2"
                    color="becks"
                    rounded
                    @click="sheet= !sheet; control = 1"
                >
                    <v-icon>mdi-share-variant</v-icon>&nbsp;Comparte tu foto
                </v-btn>
                <br>
                <v-btn class="red mx-auto" rounded @click="eliminarFoto()">
                    Eliminar Foto
                </v-btn>
                <br>
                <v-btn class="becks mx-auto mt-2" rounded @click="modal = !modal">
                    REGRESAR
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog"
            max-width="350"
        >
        <v-card 
            class="becks"
            rounded="xl"
            max-width="350"
        >
        <v-responsive aspect-ratio="1" width="340" class="text-center justify-center ">
            <v-card-title>
                <v-spacer/>
                <v-btn
                        icon
                        color="black"
                        class="mr-n3 mb-n2"
                        @click="dialog = !dialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
            </v-card-title>
            <v-card-title class="text-center justify-center " style="font-size: 80%">
                <span style="font-size:14pt" v-if="control == 1">COMPARTIR EN:</span>
                <span style="font-size:14pt" v-if="control == 2">DESCARGAR EN:</span>
            </v-card-title>
            <v-card-actions class="text-center" v-if="control== 1">
                <v-spacer/>
                <v-btn
                    color="becks"
                    icon
                    large
                    class="mx-4 black"
                    @click="fbs()"
                >
                    <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                    color="becks"
                    icon
                    large
                    class="mx-4 black"
                    @click="twttr()"
                >
                    <v-icon >mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                    color="becks"
                    icon
                    large
                    class="mx-4 black"
                    @click="whts()"
                >
                    <v-icon >mdi-whatsapp</v-icon>
                </v-btn>
                <v-spacer/>
            </v-card-actions>
            <v-card-actions class="text-center" v-if="control== 2">
                <v-spacer/>
                <a 
                    target="_blank"
                    :href="ifinalt == '1'?'imagen/archivosFinales/'+ifinalp:'imagen/archivosFinales/'+ifinalg"
                    download="FotoBecks"
                    style="text-decoration: none"
                >
                <v-btn
                    icon
                    x-large
                    class="black"
                    color="becks"
                    min-width="80"
                    min-height="80"
                >
                    <v-icon x-large>mdi-image</v-icon>
                    
                </v-btn>
                </a>
                <v-spacer/>
                <a 
                    v-if="ifinalt == '1'? false: true"
                    target="_blank"
                    :href="'imagen/archivosFinales/'+ifinalm"
                    download="FotoBecks"
                    style="text-decoration: none"
                >
                    <v-btn
                        icon
                        x-large
                        class="black"
                        color="becks"
                        min-width="80"
                        min-height="80"
                    >
                        <v-icon x-large>mdi-video</v-icon>
                    </v-btn>
                </a>
                <v-spacer v-if="ifinalt == '1'? false: true" />
            </v-card-actions>
            <v-card-actions v-if="control== 2">
                <v-spacer/>
                <span v-if="ifinalt == '1'? true: false">JPG</span>
                <span v-else>GIF</span>
                <v-spacer v-if="ifinalt == '1'? false: true" class="mx-3"/>
                <span v-if="ifinalt == '1'? false: true">MP4</span>
                <v-spacer/>
            </v-card-actions>
            <v-card-actions v-if="control == 1">
                <v-btn
                
                class="mx-auto mt-3 black becks--text"
                rounded
                x-large
                @click="control = 2"
                >
                    <v-icon >mdi-download</v-icon>&nbsp;&nbsp;<h5>DESCARGAR</h5>&nbsp;&nbsp;
                </v-btn>
            </v-card-actions>
        </v-responsive>
        </v-card>
        </v-dialog>
        <!-- aqui comienza el bottom-sheet para la DESCARGA si es mobile-->
        <v-bottom-sheet v-model="sheet" v-if="sheet">
            <v-sheet
                class="text-center rounded-t-xl becks"
                width="100%"
            >
                <v-responsive aspect-ratio="1"  class="text-center becks justify-center rounded-t-xl">
                    <v-card-title>
                        <v-spacer/>
                        <v-btn
                                icon
                                color="black"
                                class="mr-n3 mb-n2"
                                @click="sheet = !sheet"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                    </v-card-title>
                    <v-card-title class="text-center justify-center " style="font-size: 80%">
                        <span style="font-size:14pt" v-if="control == 1">COMPARTIR EN:</span>
                        <span style="font-size:14pt" v-if="control == 2">DESCARGAR EN:</span>
                    </v-card-title>
                    <v-card-actions class="text-center" v-if="control== 1">
                        <v-spacer/>
                        <v-btn
                            color="becks"
                            icon
                            large
                            class="mx-4 black"
                            @click="fbs()"
                        >
                            <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn
                            color="becks"
                            icon
                            large
                            class="mx-4 black"
                            @click="twttr()"
                        >
                            <v-icon >mdi-twitter</v-icon>
                        </v-btn>
                        <v-btn
                            color="becks"
                            icon
                            large
                            class="mx-4 black"
                            @click="whts()"
                        >
                            <v-icon >mdi-whatsapp</v-icon>
                        </v-btn>
                        <v-spacer/>
                    </v-card-actions>
                    <v-card-actions class="text-center" v-if="control== 2">
                        <v-spacer/>
                        <a 
                            target="_blank"
                            :href="ifinalt == '1'?'imagen/archivosFinales/'+ifinalp:'imagen/archivosFinales/'+ifinalg"
                            download="FotoBecks"
                            style="text-decoration: none"
                        >
                        <v-btn
                            icon
                            x-large
                            class="black"
                            color="becks"
                            min-width="80"
                            min-height="80"
                        >
                            <v-icon x-large>mdi-image</v-icon>
                            
                        </v-btn>
                        </a>
                        <v-spacer/>
                        <a 
                            v-if="ifinalt == '1'? false: true"
                            target="_blank"
                            :href="'imagen/archivosFinales/'+ifinalm"
                            download="FotoBecks"
                            style="text-decoration: none"
                        >
                        <v-btn
                            icon
                            x-large
                            class="black"
                            color="becks"
                            min-width="80"
                            min-height="80"
                        >
                            <v-icon x-large>mdi-video</v-icon>
                        </v-btn>
                        </a>
                        <v-spacer v-if="ifinalt == '1'? false: true" />
                    </v-card-actions>
                    <v-card-actions v-if="control== 2">
                        <v-spacer/>
                        <span v-if="ifinalt == '1'? true: false">JPG</span>
                        <span v-else>GIF</span>
                        <v-spacer v-if="ifinalt == '1'? false: true" class="mx-3"/>
                        <span v-if="ifinalt == '1'? false: true">MP4</span>
                        <v-spacer/>
                    </v-card-actions>
                    <v-card-actions v-if="control == 1">
                        <v-btn
                        
                        class="mx-auto mt-3 black becks--text"
                        rounded
                        x-large
                        @click="control = 2"
                        >
                            <v-icon >mdi-download</v-icon>&nbsp;&nbsp;<h5>DESCARGAR</h5>&nbsp;&nbsp;
                        </v-btn>
                    </v-card-actions>
                </v-responsive>
            </v-sheet>
        </v-bottom-sheet>
    </v-row>
    </v-container>

</template>
<script scoped>
    import { mdiFileGifBox } from "@mdi/js";
    import menus from '../components/menus.vue'
    import participantes from '../components/participantes.vue'
    import fiesta from '../components/fiesta.vue'
    import axios from 'axios'

    export default {
        name: 'Participaciones',
        components: {
            menus,
            participantes,
            fiesta
        },
        data: () => ({
            tab: 0,
            party: [],
            fotos: [],
            show_events: "",
            imagenGrande: '',
            nombreGrande: '',
            modal: false,
            mdiFileGifBox,
            dialog: false,
            control: 1,
            sheet: false,
            ifinalg: "",
            ifinalm: "",
            ifinalp: "",
            ifinalt: "",
            uuid: '',
            ruta: "https://unlockbecks.earlybirdcontent.com/Galeria/",
        }),
        methods: {
            eliminarFoto(){
                //codigo para eliminar la participacion
                var uid = this.uuid // este es el uuid de la participacion
                if(window.confirm('¿Estás seguro de eliminar esta participación?')){
                    axios
                        .post("delete_participation.php", {
                            imagen_borrada: uid
                        })
                        .then(function (response) {
                            window.location.replace("/Participaciones");
                        })
                        .catch((error) => {
                        // console.log(error);
                        });
                }
            },
            fbs() {
                if (this.ifinalt == 1) {
                var shareURL = this.ruta + this.ifinalp;
                } else {
                var shareURL = this.ruta + this.ifinalg;
                }

                var t = "Unlock Becks";
                window.open(
                "http://www.facebook.com/sharer.php?u=" +
                    encodeURIComponent(shareURL) +
                    "&t=" +
                    encodeURIComponent(t) +
                    "?img="+ shareURL,
                "sharer",
                "toolbar=0,status=0,width=626,height=436"
                );

                axios
                .post("share.php", {
                    urlimage1: this.ifinalp,
                    urlimage2: this.ifinalg,
                })
                .then(function (response) {
                //   console.log(response);
                })
                .catch((error) => {});
                },
            whts() {
                if (this.ifinalt == 1) {
                var shareURL = this.ruta + this.ifinalp;
                } else {
                var shareURL = this.ruta + this.ifinalg;
                }

                var t = "Unlock Becks";

                // Opening URL
                window.open(
                "https://api.whatsapp.com/send/?text=" +
                    encodeURIComponent(shareURL) + ' ' +
                    encodeURIComponent(t),
                "_blank"
                );

                axios
                .post("share.php", {
                    urlimage1: this.ifinalp,
                    urlimage2: this.ifinalg,
                })
                .then(function (response) {
                //   console.log(response);
                })
                .catch((error) => {});
                },
            twttr() {
                if (this.ifinalt == 1) {
                var shareURL = this.ruta + this.ifinalp;
                } else {
                var shareURL = this.ruta + this.ifinalg;
                }

                var t = "Unlock Becks";

                window.open(
                "http://twitter.com/share?url=" +
                    encodeURIComponent(shareURL) +
                    "&text=" +
                    encodeURIComponent(t),
                "",
                "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
                );

                axios
                .post("share.php", {
                    urlimage1: this.ifinalp,
                    urlimage2: this.ifinalg,
                })
                .then(function (response) {
                //   console.log(response);
                })
                .catch((error) => {});
                },
            reviewUrl(url){
                var extension = url.substr(-3,3)
                this.uuid = url.substr(24,35)
                console.log('valores:\n'+extension+'\n'+this.uuid)
                if(extension == 'gif'){
                    this.ifinalt = 2
                    this.ifinalm =  this.uuid+'.mp4'
                    this.ifinalg = this.uuid+'.gif'
                }else{
                    this.ifinalt = 1
                    this.ifinalp = url.substr(24,39)
                }
            },
            cambiarImagen(indice){
                this.imagenGrande = this.fotos[indice].url_image
            },
            async iniciar(){
                // mandamos a pedir los eventos disponibles
                await axios.post("user_event.php", {

                }).then(function(response) {

                    // console.log(response)
                    // console.log(response.data)

                    sessionStorage.array_data2 = JSON.stringify(response.data)

                }).catch(error => {

                    // console.log(error)
                    
                })
                // aqui se imprimen un array de todos los eventos disponibles
                this.party = JSON.parse(sessionStorage.array_data2)

                
                // mandamos a pedir todas las fotos del usuario
                await axios.post("user_participations.php",{
                
                    }).then(function(response){

                        // console.log(response)
                        // console.log(response.data)

                        sessionStorage.array_foto = JSON.stringify(response.data)

                    }).catch(error => {

                        // console.log(error)

                    })
                // aqui se imprimen un array de todas las fotos del usuario en sesion
                this.fotos = JSON.parse(sessionStorage.array_foto)

            },
            cambiarP(lugar){
                axios.get('/').then(response => (
                    this.$router.push({name: lugar})
                ))
                .catch(
                    error => console.log('Hubo un error: ' + error)
                )
            },
            async comprobar(){

                await axios.post("/query_events.php", {

                }).then(function(response) {

                    // console.log(response)
                    // console.log(response.data.message)

                    if(response.data.message != 'evento_elegido'){
                        sessionStorage.show = 'mostrar'
                    }else{
                        sessionStorage.show = 'apagar'
                    }

                }).catch(error => {

                    // console.log(error)

                })

                this.show_events = sessionStorage.show_perfil
                
            },
            checkCookie(){
              axios
                  .post("check_cookie.php", {
                  
                  })
                  .then(function (response) {
                    // console.log(response.data.message)

                    if(response.data.message != 'galleta'){
                      window.location.replace("/Edad");
                    }

                  })
                  .catch((error) => {
                    //   console.log(error)
                  });
            },
            queryUser(){
                axios
                .post("query_user.php", {

                }).then(function (response){
                    // console.log(response.data.validacion)
                    if(response.data.validacion != 'existente'){
                        window.location.replace("/Edad");
                    }
                }).catch(
                    error => console.log(error)
                )
            }
        },
        created(){
            this.iniciar(),
            this.comprobar(),
            this.checkCookie(),
            this.queryUser()
        }
    }
</script>
<style scoped>
    h3.btn-perfil {
        background-color: var(--v-becks-base) !important;
        color: black;
        border-color: var(--v-becks-base) !important;
        padding: 6px;
        width: 50vh;
        border-radius: 30px;
    }
    .fondo1{
        background-image: url("../assets/perfil1.png");
        background-position-x:left;
    }
    .fondo2{
        background-image: url("../assets/perfil2.png");
        background-position-x:right;
    }
    .fondo3{
        background-image: url("../assets/perfil3.png");
        background-position-x:left;
        background-position-y: bottom; 
    }
    .textoR{
        font-size: 1vw;
        float:right;
    }
    @media (max-width: 960px){
        .textoR{
            font-size: 10pt;
        }
        .fondo3{
            background-image: url("../assets/perfilMobile.png");
            background-position-x:left;
            background-position-y: bottom; 
        }
        h3.btn-perfil {
            width: 35vh;
        } 
    }
</style>
